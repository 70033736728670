<template>
  <div class="change_bg">
    <Navbar></Navbar>
    <div class="container">
      <p class="site_title">{{ $store.state.language.lang[175] }}</p>
      <div class="row">
        <div class="col-xl-3 col-md-4 col-sm-6 col-12" v-for="(item,index) in $store.state.journal.results" :key="index">
          <div class="paper_card">
            <img :src="item.image">
            <div class="paper_footer">
              <div class="paper_title">{{ $store.state.language.lang[165] }} №66</div>
              <div class="paper_title">01.03.2022</div>
              <a :href="item.file" target="_blank" class="links">
                <div class="paper_buttons">
                  <div class="paper_button_text">{{ $store.state.language.lang[174] }} </div>

                  <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="24.669" cy="24" rx="24.669" ry="24" fill="#00B57F" fill-opacity="0.05"/>
                    <path d="M35.012 26.6668C35.012 26.2525 34.6762 25.9168 34.262 25.9168C33.8478 25.9168 33.512 26.2525 33.512 26.6668H35.012ZM15.8257 26.6668C15.8257 26.2525 15.4899 25.9168 15.0757 25.9168C14.6615 25.9168 14.3257 26.2525 14.3257 26.6668H15.8257ZM31.1 22.8143C31.3969 22.5255 31.4035 22.0507 31.1146 21.7538C30.8258 21.4569 30.351 21.4503 30.0541 21.7392L31.1 22.8143ZM24.7048 27.9898L24.1818 28.5273C24.473 28.8106 24.9367 28.8106 25.2278 28.5273L24.7048 27.9898ZM19.3556 21.7392C19.0587 21.4503 18.5838 21.4569 18.295 21.7538C18.0062 22.0507 18.0127 22.5255 18.3096 22.8143L19.3556 21.7392ZM25.4548 14.6768C25.4548 14.2625 25.119 13.9268 24.7048 13.9268C24.2906 13.9268 23.9548 14.2625 23.9548 14.6768H25.4548ZM23.9548 27.9728C23.9548 28.387 24.2906 28.7228 24.7048 28.7228C25.119 28.7228 25.4548 28.387 25.4548 27.9728H23.9548ZM33.512 26.6668V29.7138H35.012V26.6668H33.512ZM33.512 29.7138C33.512 30.9372 32.4869 31.9638 31.1784 31.9638V33.4638C33.2762 33.4638 35.012 31.8043 35.012 29.7138H33.512ZM31.1784 31.9638H18.1593V33.4638H31.1784V31.9638ZM18.1593 31.9638C16.8508 31.9638 15.8257 30.9372 15.8257 29.7138H14.3257C14.3257 31.8043 16.0615 33.4638 18.1593 33.4638V31.9638ZM15.8257 29.7138V26.6668H14.3257V29.7138H15.8257ZM30.0541 21.7392L24.1818 27.4522L25.2278 28.5273L31.1 22.8143L30.0541 21.7392ZM25.2278 27.4522L19.3556 21.7392L18.3096 22.8143L24.1818 28.5273L25.2278 27.4522ZM23.9548 14.6768V27.9728H25.4548V14.6768H23.9548Z" fill="#00B57F"/>
                  </svg>

                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3"></div>
        <div class="col-md-3"></div>
      </div>
      <div class="pagenation">
        <paginate
            :page-count="$store.state.journal.total_pages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from '../components/Header/Navbar'
import Footer from '../components/Header/Footer'
export default {
  components:{
    Navbar,
    Footer
  },
  mounted() {
    this.$store.dispatch('journal',1)
  },
  methods:{
    clickCallback (pageNum){

      this.$store.dispatch('journal',pageNum)
    },
  }
}
</script>